(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.home.championships.championship.draw.controller:DrawCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.championships.championship.draw')
    .controller('DrawCtrl', DrawCtrl);

  function DrawCtrl($stateParams, $mdToast, $filter, championship, categories, sports, genders, teams, ChampionshipCalendar, matchDaysDates, $state) {
    var vm = this;
    vm.ctrlName = 'DrawCtrl';

    vm.championship = championship;

    vm.category = $filter('translate')('CATEGORIES.' + findItemById(categories, vm.championship.categoryId).name);
    vm.sport = $filter('translate')('SPORTS.' + findItemById(sports, vm.championship.sportId).name);
    vm.gender = $filter('translate')(findItemById(genders, vm.championship.genderId).name);

    vm.teams = angular.copy(teams);
    vm.matchDays = fillMatchDays(championship.rounds, vm.teams.length, matchDaysDates);

    vm.drawChampionship = drawChampionship;

    function fillMatchDays(rounds, numberOfTeams, matchDaysDates) {
      var matchDays = [];
      var matchesForRound = (numberOfTeams % 2 === 0)? numberOfTeams - 1: numberOfTeams;
      _.range(matchesForRound * rounds ).forEach(function(index) {
        var date = _.isUndefined(matchDaysDates[index]) ? new Date() : matchDaysDates[index]
        matchDays.push({
          name: 'matchDay_' + index,
          date: date
        });
      });

      return matchDays;
    }

    function drawChampionship() {
      var calendarModel = {
        matchDates: _.map(vm.matchDays, function(matchDay) {
          return moment(matchDay.date).format('YYYY-MM-DD');
        }),
        orderedTeams: _.map(vm.teams, 'id')
      };
      ChampionshipCalendar.save({championshipId: $stateParams.championshipId}, calendarModel, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CALENDAR.SUCCESSFUL_CREATE_CALENDAR'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('public.championshipCalendar', {championshipId: $stateParams.championshipId}, {reload: true});
      }, function (err) {
        var message = err.data || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
